<template>
    <div class="exhibitors-list">
        <h2 v-html="title"></h2>
        <div class="logos-container">
            <div class="logo-item" v-for="(item, idx) in list" :key="idx">
                <a :href="item.url" target="_blank">
                <img :src="item.img" :alt="item.alt" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "ExhibitorsList",
    props: ['title', 'type'],
    data(){
        return  {
            exhibitors : [
                {
                    img : require('@/assets/logos-exhibitors/Logo_Plateforme_DYNACENTRIX_v1.png'),
                    alt : 'Logo Dynacentrix',
                    url : 'https://www.dynacentrix.com/',
                    type : 2
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Exposant_Exotic_Systems.png'),
                    alt : 'Logo Exotic Systems',
                    url : 'https://exotic-systems.com/',
                    type : 2
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Exposant_OCEAN_AI_v1.png'),
                    alt : 'Logo Oceans AI',
                    url : 'https://oceansai.tech/',
                    type : 2
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Exposant_ROVENSO.png'),
                    alt : 'Logo Rovenso',
                    url : 'https://www.rovenso.com/',
                    type : 2
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Exposant-Solings.png'),
                    alt : 'Logo Solings',
                    url : 'https://solings.com/',
                    type : 2
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Exposant_STACKEO_v1.png'),
                    alt : 'Logo Stackeo',
                    url : 'https://www.stackeo.io/',
                    type : 2
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Exposant_PERCALL.png'),
                    alt : 'Logo Percall',
                    url : 'https://www.percall.fr/'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Exposant-SYNTHES3D.png'),
                    alt : 'Logo Synthes3d',
                    url : 'https://www.synthes3d.com/en/'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Exposant_PTC_1.png'),
                    alt : 'Logo PTC',
                    url : 'https://www.ptc.com/en'
                },
                {
                    img : require('@/assets/logos-exhibitors/Log_Outsight_v1.png'),
                    alt : 'Logo Outsight',
                    url : 'https://www.outsight.ai/',
                    type : 2
                },
               

            ]
        }
    }, 

    computed : {
        list() {
            if(!this.type) {
                return this.exhibitors;
            }
            else {
                return this.exhibitors.filter(e => e.type == 2);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    h2 {
        color:$main-color;
        margin-top:20px;
    }
</style>