<template>
    <div class="modal" @click="$emit('close')">
        <div class="close">X</div>
        <div class="modal-container" @click.stop>
            <vue-vimeo-player ref="player" :video-id="videoID" :player-width="width"  :player-height="height" ></vue-vimeo-player>
        </div>
    </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';

export default {
    name : "VideoModal",
    components: { vueVimeoPlayer },
    props : ["videoID"],
    data() {
        return {
            width : null,
            height:null
        }
    },
    created() {
        const current = window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth;

        if(current < 1400) {
            this.width = current- 20;
        }else {
            this.width = 1400;
        }
        this.height = this.width * (16 / 9);
    }
}
</script>

<style lang="scss" scoped>
    .modal {
        background:rgba(0,0,0,0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top:0;
        bottom: 0;
        left:0;
        right: 0;
        z-index:999;
    }

    .modal-container {
        max-width: 90%;
        width:1200px;
        // display: flex;
        // justify-content: center;
    }

    .close {
        position:absolute;
        top:20px;
        right:20px;
        cursor: pointer;
        color: #fff;
        font-size:25px;
        font-weight:700;
    }
</style>