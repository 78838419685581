import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from './../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/exhibitors',
    name: 'Exhibitors',
    component: () => import('../views/Exhibitors.vue')
  },
  {
    path: '/legal-notices',
    name: 'LegalNotices',
    component: () => import('../views/LegalNotices.vue')
  },
  {
    path: '/round-tables',
    name: 'RoundTables',
    component: () => import('../views/RoundTables.vue')
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: () => import('../views/Sponsors.vue')
  },
  {
    path: '/visitors',
    name: 'Visitors',
    component: () => import('../views/Visitors.vue')
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to , from, next) => {
  store.state.menu_open = false
  next();
})



export default router
