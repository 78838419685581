<template>
    <div class="main-container">
      <!-- BANNER -->
      <div class="banner">
        <div class="banner-container">
          <h1>
            <span class="title-second">AR & IoT Digital Transformation</span><br />
            22 - 23 - 24 March, 2022
          </h1>
          <div class="banner-sep"></div>
          <h2>A fully <span class="title-second">interactive virtual event</span> dedicated to <span class="title-second">AR and IoT professionals</span> not to be missed!</h2>
          <div class="actions-btn sponsors-btn" @click="displayVideo('637372767')">Watch our Teaser</div>
        </div>
      </div>

      <!-- SECTION : PARTICIPATE -->
      <section class="section-participate">
          <h2>Primary Topics for the Virtual Conference</h2>
          <div class="theme">
            <ul>
              
              <li>Artificial Intelligence</li>
              <li>Augmented & Virtual Reality</li>
              <li>Data Explorer</li>
              <li>Digital Cockpit</li>
              <li>Digital Inspection</li>
              <li>Industry 4.0</li>
              <li>Legacy Replacement</li>
              <li>Machine Learning & Analytics</li>              
              <li>Preventive Maintenance</li>
              
            </ul>
          </div>
          <h2>Advantages of Participating in this Virtual Event</h2>
          <div class="section-box">
            <div class="particpate-container">
              <div class="participate-item" v-for="(item, idx) in participate" :key="idx">
                <div class="participate-puce"></div>
                <p class="participate-title" v-html="item.title"></p>
                <p class="participate-text" v-html="item.text"></p>
              </div>
            </div>
          </div>
      </section>

      <!-- SECTION EXHIBIT -->
      <section class="section-exhibit">
        <div class="exhibit-sep-top"></div>
        
        <h2>Why Become an Exhibitor?</h2>
        <div class="exhibit-container">
          <div class="exhibit-item" v-for="(item, idx) in exhibit1" :key="idx">
            <h3 v-html="item.title"></h3>
            <p v-html="item.text"></p>
          </div>
        </div>
        <div class="btn-container">
          <router-link to="/exhibitors" class="exhibit-btn">Become an Exhibitor</router-link>
        </div>
        <h2>Why Become an Attendee?</h2>
        <div class="exhibit-container">
          <div class="exhibit-item" v-for="(item, idx) in exhibit2" :key="idx">
            <h3 v-html="item.title"></h3>
            <p v-html="item.text"></p>
          </div>
        </div>
        <div class="btn-container">
          <router-link to="/visitors" class="exhibit-btn">Attend the Event</router-link>
        </div>
        <div class="exhibit-sep-bottom"></div>
      </section>

      <!-- SECTION SPONSORS -->
      <section class="section-sponsors">
        <div class="our-sponsors-container">
          <h2>Our <span class="title-primary">Sponsors</span> </h2>
          <div class="logos-container">
            <div class="logo-item sponsors-item" v-for="(item, idx) in sponsors" :key="idx">
              <a :href="item.url" target="_blank">
                <img :src="item.img" :alt="item.alt" />
              </a>
            </div>
          </div>
          <div class="btn-container">
            <router-link to="/sponsors" class="actions-btn sponsors-btn">Become a Sponsor</router-link>
          </div>
        </div>
        <!-- <div class="our-exhibitor-container">
          <h2>Our <span class="title-primary">exhibitors</span> </h2>
          <div class="logos-container">
            <div class="logo-item" v-for="(item, idx) in exhibitors" :key="idx">
              <a :href="item.url" target="_blank">
                <img :src="item.img" :alt="item.alt" />
              </a>
            </div>
          </div>
          <div class="btn-container">
            <router-link to="/exhibitors" class="actions-btn sponsors-btn">Become an exhibitor</router-link>
          </div>
        </div> -->
        <exhibitors-list
                title="<span class='grey'>Our</span> Exhibitors"
                type="2"
            >
        </exhibitors-list>
        <div class="btn-container">
          <router-link to="/exhibitors" class="actions-btn sponsors-btn">Become an Exhibitor</router-link>
        </div>
        <div class="actions-container">
          <div class="actions-col">
            <h3>REGISTER FOR THE EVENT</h3>
            <a href="https://percallnext.my-virtual-convention.com/register" target="_blank" class="actions-btn">Register</a>
          </div>
          <div class="actions-col">
            <h3>ALREADY HAVE AN ACCOUNT?</h3>
            <a href="https://percallnext.my-virtual-convention.com/login" target="_blank" class="actions-btn actions-login">Log in</a>
          </div>
        </div>
      </section>
      <!-- SECTION ORGANIZED -->
      <section class="section-organized">
        <h2>Organized by</h2>
        <figure>
          <img src="./../assets/logo-percall-orga.png" alt="logo Percall group" />
        </figure>
      </section>
      <!-- SECTION PARTNERS -->
      <!-- <section class="section-partners">
        <h2>Partners</h2>
        <div class="partners-container">

          <div class="partners-col">
            <a href="http://www.percall.fr" target="_blank">
              <img src="./../assets/sponsors/percall.jpg" alt="logo PERCALL" />
            </a>
          </div>
          <div class="partners-col">
            <a href="https://www.ptc.com/en/" target="_blank">
              <img src="./../assets/sponsors/ptc.png" alt="logo PTC" />
            </a>
          </div>
          <div class="partners-col">
            <a href="https://www.synthes3d.com/en/" target="_blank">
              <img src="./../assets/logo-synthes3d.png" alt="logo Synthes3d" />
            </a>
          </div>
        </div>
      </section> -->
      <video-modal 
        v-if="video"
        :videoID="videoID"
        v-on:close="closeVideo"
      >
      </video-modal>
    </div>
</template>

<script>
import ExhibitorsList from "./../components/ExhibitorsList.vue";
import VideoModal from "./../components/VideoModal.vue";

export default {
  name: 'Home',
  components : {
    ExhibitorsList,
    VideoModal
  },
  data() {
    return {
      video : false,
      videoID : null,
      participate : [
        {
          title : 'High volume of attendees',
          text : 'AR & IoT professionals and industry key opinion leaders'
        },
        {
          title : 'Affordable',
          text : 'Cost of virtual event is 75% less expensive than in-person event'
        },
        {
          title : 'Sales productivity',
          text : 'No time lost in travelling to remote event, so more time with customers'
        },
        {
          title : 'Branding',
          text : 'New opportunity for virtual marketing to potential customers'
        },
        {
          title : 'Secure communication platform',
          text : 'Interact with potential customers and partners'
        },
        {
          title : 'Key thought leaders',
          text : 'Leading edge insights and possible partnership opportunities'
        },
        {
          title : 'Web-based communication program',
          text : 'Increased visibility and search-engine optimization'
        },
        {
          title : '"Green Tech" initiative',
          text : 'No travel required, low carbon footprint'
        },
      ],
      exhibit1 : [
        {
          title : 'Promote',
          text : 'your products and services'
        },
        {
          title : 'Increase',
          text : 'your company’s visibility'
        },
        {
          title : 'Unveil',
          text : 'your latest innovations'
        },
      ],
      exhibit2 : [
        {
          title : 'Visit & Interact',
          text : 'with the exhibitors'
        },
        {
          title : 'Discover',
          text : 'the key players in the AR & IoT industries'
        },
        {
          title : 'Surveil',
          text : 'the AR & IoT markets'
        },
      ],
      sponsors : [
          {
          img : require('@/assets/sponsors/percall.jpg'),
          alt : 'Logo Percall',
          url : 'http://www.percall.fr'
        },
        {
          img : require('@/assets/sponsors/ptc.png'),
          alt : 'Logo PTC',
          url : ' https://www.ptc.com/en/'
        },
        {
          img : require('@/assets/sponsors/synthes3d.png'),
          alt : 'Logo SYNTHES3D',
          url : 'https://www.synthes3d.com/en/'
        },
      ],
      exhibitors : [
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
      ]
    }
  },
  methods: {
    displayVideo(id) {
      this.video = true;
      this.videoID = id;
    },
    closeVideo() {
      this.videoID = null;
      this.video = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('./../assets/Home_slider.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1000px;
    max-width: 96%;
  }

  .banner h1 {
    text-align: center;
    color:$main-color;
    font-size: 50px;
    font-weight:700;
    line-height: 1.3em;
    letter-spacing: 1px;
    margin:0;
    padding:0;
  }

  .banner .title-second {
    color:#1a1a1a;
    font-weight:700;
  }

  .banner-sep {
    height:2px;
    width:1000px;
    max-width: 90%;
    background:$main-color;
    margin:40px auto;
  }

  .banner h2 {
    text-align: center;
    color:#1a1a1a;
    font-size: 32px;
    font-weight:400;
    line-height: 1.3em;
    letter-spacing: 1px;
    margin:0;
    padding:0;
  }

  .section-participate {
    padding:80px 0;
    background-image: url('./../assets/bg-home-participate.png');
    background-repeat: no-repeat;
    background-position: center right;
  }

  .particpate-container {
    width:90%;
    margin: 80px auto 0 auto;
  }

  .theme {
    display: flex;
    justify-content: center;
    max-width: 90%;
margin:40px auto 20px auto;
  }

  .theme ul {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin:0;
    padding:0;
  }

  .theme li {
    color:$main-color;
    font-size:20px;
    margin-bottom:20px;
    list-style: none;
    position: relative;
    font-weight:700;
    text-align: center;
}

// .theme li::before {
//     content:'';
//     width:25px;
//     height:20px;
//     background-image: url('./../assets/puce.png');
//     background-position: center;
//     background-size: initial;
//     background-repeat: no-repeat;
//     position: absolute;
//     left:-40px;
//     top:3px;
// }

  /* participate */

  .participate-item {
    display: flex;
    justify-content: flex-start;
    margin-top:30px;
    margin-bottom:30px;
  }

  .participate-puce{
    height:20px;
    width:23px;
    min-width: 23px;;
    background-image: url('./../assets/puce.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 20px 0 40px;
  }

  .participate-title {
    margin: 0;
    width:300px;
    min-width: 300px;
    color:$main-color;
    font-weight:700;
  }

  .participate-text {
    color:$text-color;
    margin:0;
  }

  /* Exhibit */

  .section-exhibit {
    padding:180px 0;
    background-image: url('./../assets/bg-home-exhibit.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  .section-exhibit h2, .section-exhibit .exhibit-item h3 {
    color:#fff;
  }

  .exhibit-sep-top {
    position: absolute;
    top:0;
    left:0;
    right:0;
    height:100px;
    background-image: url('./../assets/sep-home-exhibit-top.png');
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .exhibit-sep-bottom {
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    height:100px;
    background-image: url('./../assets/sep-home-exhibit-bottom.png');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .exhibit-container {
    display: flex;
    justify-content: center;
    margin-bottom:20px;
    flex-wrap: wrap;
  }

  .exhibit-item {
    height:120px;
    width:220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border:1px solid #fff;
    border-radius:10px;
    margin: 20px 40px;
    padding:0 40px;
  }

  .exhibit-item h3 {
    margin:0;
    padding:0;
    color:$secondary-color;
    font-weight:700;
  }

  .exhibit-item p {
    margin:0;
    padding:0;
    color:#fff;
    font-weight:500;
    text-align: center;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    width:100%;
    margin-bottom:80px;
  }

  .exhibit-btn {
    padding:10px 20px;
    background-color: #fff;
    border:1px solid #fff;
    border-radius:10px;
    color:$secondary-color;
    font-size:20px;
    font-weight:700;
    text-decoration: none;
    transition: 0.5s;
  }

  .exhibit-btn:hover {
    border:1px solid $secondary-color;
  }

  //hide empty exhibitors
  .our-exhibitor-container {
    display: none;
  }
 
  /* SECTION SPONSORS */
  .section-sponsors {
    padding:80px 0;
    background-image: url('./../assets/bg-home-sponsors.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }


  /* ORGANIZED */
  .section-organized {
    background-image: url('./../assets/home-bg-otw.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section-organized h2 {
    color:#fff;
  }

  .section-organized img {
    width:400px;
    height:auto;
    margin:-20px auto 0 auto;
    max-width: 90%;
  }

  /* PARTNERS */
  .section-partners {
    padding:80px 0;
  }

  .partners-container {
    width: $box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: 90%;
  }

  .partners-col {
    width:33%;
    display: flex;
    justify-content: center;
  }

  .partners-col img {
    width:220px;
    height:auto;
  }

  .title-primary {
    color:$main-color;
  }

  .actions-container {
    margin-top:80px;
  }

  @media screen and (max-width:1100px) {
    
    .partners-container {
      flex-direction: column;
    }

    .partners-col {
      width:100%;
      margin-top:20px;
      margin-bottom:20px;
    }
  }

  @media screen and (max-width:768px) {
    .section-sponsors, .section-participate {
      padding:40px 0;
    }

    .particpate-container {
      margin-top:40px;
    }

    .banner-container {
      margin-top:40px;
    }

    .banner h1 {
      font-size:30px;
    }

    .banner h2 {
      font-size:25px;
    }
  }
  


</style>
