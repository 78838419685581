<template>
    <header>
        <div class="header-bg">
            <div class="header-container">
                <div class="logo-container" @click="$router.push('/')">
                    <img src="./../assets/logo-percall-iot.png" alt="logo" />
                </div>
                <div class="menu-container">
                    <nav class="nav-container">
                        <router-link class="menu-link" to='/'>Home</router-link>
                        <router-link class="menu-link" to='/exhibitors'>Exhibitors</router-link>
                        <router-link class="menu-link" to='/sponsors'>Sponsors</router-link>
                        <router-link class="menu-link" to='/visitors'>Visitors</router-link>
                        <router-link class="menu-link" to='/round-tables'>Roundtables</router-link>
                        <router-link class="menu-link" to='/about-us'>About us</router-link>
                        <router-link class="menu-link" to='/contact'>Contact</router-link>
                    </nav>
                    <div class="menu-sep"></div>
                    <div class="links-container">
                        <a href="https://percallnext.my-virtual-convention.com/register" target="_blank" class="link-ext link-register">Register</a>
                        <a href="https://percallnext.my-virtual-convention.com/login" target="_blank" class="link-ext link-login">Log in</a>
                    </div>
                </div>
                 <!-- MENU RESP -->
                <div class="menu-header-resp">
                    <div class="burger-nav" @click="$store.state.menu_open = !$store.state.menu_open" :class="$store.state.menu_open ? 'open' : ''" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <nav class="menu-header-resp-content" v-if="$store.state.menu_open">
                        <router-link class="menu-link" to='/'>Home</router-link>
                        <router-link class="menu-link" to='/exhibitors'>Exhibitors</router-link>
                        <router-link class="menu-link" to='/sponsors'>Sponsors</router-link>
                        <router-link class="menu-link" to='/visitors'>Visitors</router-link>
                        <router-link class="menu-link" to='/round-tables'>Roundtables</router-link>
                        <router-link class="menu-link" to='/about-us'>About us</router-link>
                        <router-link class="menu-link" to='/contact'>Contact</router-link>
                        <a href="https://percallnext.my-virtual-convention.com/register" target="_blank" class="link-ext-resp menu-link link-register">Register</a>
                        <a href="https://percallnext.my-virtual-convention.com/login" target="_blank" class="link-ext-resp menu-link link-login">Log in</a>
                        
                    </nav>
                </div>
            </div>
        </div>
        <div class="header-sep"></div>
    </header>
</template>

<script>
export default {
    name :'HeaderApp',
}
</script>

<style lang="scss" scoped>
    header {
        display:flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top:0;
        right:0;
        left:0;
        z-index: 999;
    }

    .header-bg {
        background-color: #fff;
        width: 100%;
    }

    .header-container {
        display:flex;
        justify-content: space-between;
        align-items: center;
        width:90%;
        margin:0 auto;
        max-width: $box;
        height:100px;       

    }

    .logo-container {
        cursor: pointer;
    }

    .menu-link {
        margin:0 20px;
        font-size:18px;
        color:$text-color;
        font-weight: 400;
        text-decoration: none;
        transition:0.5s;
    }

    .menu-link:hover {
        cursor: pointer;
        color: $main-color;
    }

    .menu-link.router-link-exact-active {
        color: $main-color;
        font-weight:700;
    }

    .menu-container {
        display: flex;
        align-items: center;
    }

    .menu-sep {
        height:40px;
        width:2px;
        background-color: $main-color;
        margin-right:40px;
    }

    .links-container {
        display: flex;
    }

    .link-ext {
        margin:0 5px;
        height:40px;
        width:130px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius:10px;
        cursor: pointer;
        transition:0.5s;
        font-size:18px;
        text-decoration: none;
    }

    .link-register {
        background-color: $secondary-color;
        border:1px solid $secondary-color;
    }

    .link-register:hover {
        background-color: #fff;
        color:$secondary-color; 
    }

    .link-login {
        background-color: $main-color;
        border:1px solid $main-color;
    }

    .link-login:hover {
        background-color: #fff;
        color:$main-color; 
    }

    .header-sep {
        width:100%;
        height:50px;
        background-image: url('./../assets/header-sep.png');
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    /* MENU RESP */
    .menu-header-resp {
        display: none;
    }

    .burger-nav {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 50px auto;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }

    .burger-nav span {
        display: block;
        position: absolute;
        height: 7px;
        width: 100%;
        background: $main-color;
        border-radius: 7px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    .burger-nav span:nth-child(1) {
        top: 0px;
    }

    .burger-nav span:nth-child(2),
    .burger-nav span:nth-child(3) {
        top: 14px;
    }

    .burger-nav span:nth-child(4) {
        top: 28px;
    }

    .burger-nav.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    .burger-nav.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .burger-nav.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .burger-nav.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    .menu-header-resp-content {
        position: fixed;
        top: 85px;
        width: 300px;
        right: 0;
        background: #fff;
        border-bottom: 1px solid $grey;
        display: flex;
        flex-direction: column;
    }

    .menu-header-resp-content .menu-link {
        width: calc(100% - 40px);
        border: none;
        height: 40px;
        justify-content: flex-start;
        padding: 0 20px;
        align-items: center;
        text-decoration: none;
        display: flex;
    }

    .menu-header-resp-content .menu-link .menu-text {
        display: flex;
        position: relative;
        width: calc(100% - 22px);
        height: 40px;
        align-items: center;
        bottom: 0;
        text-decoration: none;
        text-align: left;
        justify-content: flex-start;
        margin-left: 20px;
    }

    .menu-header-resp-content .router-link-exact-active.menu-item {
        background: $main-color;
    }

    .menu-header-resp-content .router-link-exact-active .menu-highlight {
        display: none;
    }

    .menu-header-resp-content .router-link-exact-active.menu-item .icon-menu:before,
    .menu-header-resp-content .router-link-exact-active.menu-item .menu-text {
        color: #fff;
    }

    .link-ext-resp.menu-link {
        color:#fff;
        margin:0;
        display: flex;
        align-items: center;
        padding:0 40px;
    }

    @media screen and (max-width: 1400px) {
        .menu-link {
            margin:0 10px;
        }

        .menu-sep {
            margin-right:10px;
        }

    }

    @media screen and (max-width: 1325px) {
        .menu-header-resp {
            display: flex;
        }

        .header-container {
            max-width: 90%;
        }

        .menu-container {
            display: none;
        }

        .logo-container img {
            max-width: calc(100% - 100px);
        }
    }

    
</style>