<template>
    <footer>
        <div class="footer-sep" v-if="$route.name != 'Sponsors' && $route.name != 'Exhibitors' && $route.name != 'Contact' && $route.name != 'Home' && $route.name != 'RoundTables'" ></div>
        <div class="footer-content">
            <p>© Copyright {{ currentYear }} - Percall Next Virtual Event - <a href="https://www.percallgroup.com/legal-notices/" target="_blank">Legal notices</a> - <a href="https://home.my-virtual-convention.com/" target="_blank">MY-VIRTUAL-CONVENTION</a> by <a href="https://www.synthes3d.com/en/" target="_blank">SYNTHES'3D</a></p>
        </div>
    </footer>
</template>

<script>
export default {
    name :'FooterApp',
    data() {
        return {
            currentYear : new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss" scoped>
    footer {
        width: 100%;
        background-color: $grey;
    }

    .footer-sep {
        width: 100%;
        height:120px;
        background-image: url('./../assets/sep-footer.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .footer-content p {
        color:$secondary-color;
        text-align:center;
        margin:0 auto;
        padding:20px;
        font-weight:300;
        font-size:15px;
    }

    .footer-content p a {
        color:$secondary-color;
        text-decoration: none;
        cursor: pointer;
    }

    .footer-content p a:hover {
        text-decoration: underline;
    }

    .footer-focus {
        color:$main-color;
    }

    @media screen and (max-width:768px) {
        .footer-content p {
            font-size:13px;
        }

        .footer-sep {
            height:40px;
        }
    }
</style>