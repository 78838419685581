<template>
  <div id="app">
    <HeaderApp></HeaderApp>
    <router-view/>
    <FooterApp></FooterApp>
  </div>
</template>

<script>
import HeaderApp from './components/HeaderApp.vue'
import FooterApp from './components/FooterApp.vue'

export default {
  name : 'App',
  components : {
    HeaderApp,
    FooterApp
  }
}
</script>


<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');

body {
  margin:0;
  padding:0;
  font-family: $font;
}

.main-container {
  min-height:calc(100vh - 180px);
}

.main-container h2 {
  text-align: center;
  color:$secondary-color;
  font-size:30px;
  font-weight: 700;
  margin:0 0 40px 0;
  padding:0;
  letter-spacing: 1px;
  line-height: 1.3em;
  // text-transform: uppercase;
}

.section-box {
  width:$box;
  margin:0 auto;
  max-width: 90%;
}

.title-solo {
  color:$main-color;
  font-size:40px;
  font-weight:700;
  padding: 0;
  margin-top:200px;
  margin-bottom: 80px;
  text-align: center;
}

/* LOGOS DISPLAY */

.logos-container {
  width: $box;
  max-width: 90%;
  margin:0 auto;
  display: flex;
  flex-wrap: wrap;
      justify-content: center;

}

.logo-item {
  width:calc(25% - 20px);
  min-width: 250px;
  display: flex;
  justify-content: center;
  margin:10px;
}  

.sponsors-item.logo-item {
  width:calc(33% - 20px);
}

.logo-item img {
  width:200px;
}

/* FIN LOGOS DISPLAY */

  h2 .title-focus {
      color:$main-color;
      font-weight:400;
  }

/* ACTIONS CONTAINER */
  .actions-container {
    width:$box;
    margin: 0 auto;
    display: flex;
    max-width: 90%;
  }

  .actions-col {
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .actions-col:nth-child(1) {
    border-right:2px solid $text-color;
  }

  .actions-col h3{
    font-size:25px;
    color:$secondary-color;
    text-align:center;
    font-weight: 700;
    padding:0;
    margin:0;
  }

  .actions-btn {
    width:320px;
    height:50px;
    background-color: $secondary-color;
    border:1px solid $secondary-color;
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:80px;
    border-radius:10px;
    font-size:20px;
    font-weight:500;
    text-decoration: none;
    transition: 0.5s;
    cursor: pointer;
  }

  .banner-container .actions-btn {
    margin-top:60px;
  }

  .actions-btn.sponsors-btn.margin-btn {
    margin-top:40px;
  }

  .actions-btn.actions-login, .actions-btn.sponsors-btn {
    background:$main-color;
    border:1px solid $main-color;
  }

  .actions-btn:hover{
    background:#fff;
    color:$secondary-color;
  }

  .actions-btn.actions-login:hover, .actions-btn.sponsors-btn:hover {
    color:$main-color;
  }

  .actions-btn.sponsors-btn  {
    margin-top:20px;
    margin-bottom: 80px;
  }

  .grey {
    color: $secondary-color;
  }

  .modal-container iframe {
    width:100%;
    max-width: 100%;
  }

  .table-btn .addeventatc {
    padding:10px !important;
    text-align: center !important;
    background:$main-color !important;
    border-radius: 10px !important;
    color:#fff !important;
    font-weight:400;
    text-shadow: none;
    box-shadow: none !important;
    border:1px solid $main-color;
    font-family: $font !important;
  }

  .table-btn .addeventatc .addeventatc_icon {
    display: none;
  }

  .table-btn .copyx {
    display: none !important;
  }

  @media screen and (max-width:900px) {
    .actions-container {
      flex-direction: column;
    }

    .actions-col:nth-child(1) {
      border-right:0;
      margin-bottom:60px;
    }

    .actions-col {
      width:100%;
    }

    .actions-col h3 {
      font-size:20px;
    }

    .actions-btn {
      margin-top:20px;
      width: 200px;
      height: 40px;
      font-size:18px;
      text-align: center;
      padding:5px;
    }
  }

  @media screen and (max-width:768px) {
    .title-solo {
      font-size:30px;
      margin-bottom:40px;
    }

    .main-container h2 {
      font-size:25px;
      margin:0 0 20px 0;
    }



    .participate-item {
      flex-direction: column;
      align-items: center;
    }

    .participate-puce {
      display: none;
    }

    .participate-title, .participate-text {
      text-align: center;
    }
  }

</style>
