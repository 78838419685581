import { extend } from "vee-validate";
import {
  required,
  max, 
  email,

} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});


extend("max", {
  ...max,
  message: (_, { length }) =>
    `This field cannot contain more than ${length} characters!`,
});


extend("email", {
  ...email,
  message: "Invalid format email",
});







